import { css } from '@emotion/core';
import { Responsive } from '@m2/blueprint-ui';

export const SECTION_PADDING_XL = '120px';
export const SECTION_PADDING_L = '54px';
export const SECTION_PADDING_M = '38px';
export const SECTION_PADDING_S = '0px';

export const sharedWrapperPadding = css`
	padding-left: ${SECTION_PADDING_XL};
	padding-right: ${SECTION_PADDING_XL};
	@media (max-width: 1300px) {
		padding-left: ${SECTION_PADDING_L};
		padding-right: ${SECTION_PADDING_L};
	}

	@media (max-width: 1100px) {
		padding-left: ${SECTION_PADDING_M};
		padding-right: ${SECTION_PADDING_M};
	}

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		padding-left: ${SECTION_PADDING_S};
		padding-right: ${SECTION_PADDING_S};
	}
`;
