import React, { ReactNode } from 'react';
import {
	CustomNextLinkContext,
	ICustomNextLinkContext,
} from './CustomNextLinkContext';

interface ICustomNextLinkProps extends ICustomNextLinkContext {
	children: ReactNode;
}

export const CustomNextLink = ({
	children,
	...props
}: ICustomNextLinkProps) => (
	<CustomNextLinkContext.Provider value={props}>
		{children}
	</CustomNextLinkContext.Provider>
);
