import SunnyIcon from '../../../assets/icons/weather/Sunny.png';
import FogIcon from '../../../assets/icons/weather/Fog.png';
import FreezingRainIcon from '../../../assets/icons/weather/Freezing-Rain.png';
import IceIcon from '../../../assets/icons/weather/Ice.png';
import MostlyCloudyWithShowersIcon from '../../../assets/icons/weather/Mostly-cloudy-with-Showers.png';
import MostlyCloudyWithSnowIcon from '../../../assets/icons/weather/Mostly-Cloudy-with-Snow.png';
import MostlyCloudyWithThunderShowersIcon from '../../../assets/icons/weather/Mostly-Cloudy-with-Thunder-Showers.png';
import PartlySunnyIcon from '../../../assets/icons/weather/Partly-Sunny.png';
import RainIcon from '../../../assets/icons/weather/Rain.png';
import ShowersIcon from '../../../assets/icons/weather/Showers.png';
import SleetIcon from '../../../assets/icons/weather/Sleet.png';
import SnowIcon from '../../../assets/icons/weather/Snow.png';
import WindyIcon from '../../../assets/icons/weather/Windy.png';
import CloudyIcon from '../../../assets/icons/weather/Cloudy.png';
import ThunderstormsIcon from '../../../assets/icons/weather/Thunderstorms.png';

export function getWeatherIcon(currentWeatherIcon: string) {
	switch (currentWeatherIcon) {
		case 'sunny':
			return SunnyIcon;

		case 'partly_sunny':
			return PartlySunnyIcon;

		case 'cloudy':
			return CloudyIcon;

		case 'fog':
			return FogIcon;

		case 'showers':
			return ShowersIcon;

		case 'mostly_cloud_with_showers':
			return MostlyCloudyWithShowersIcon;

		case 'thunderstorms':
			return ThunderstormsIcon;

		case 'mostly_cloudy_with_thunder_showers':
			return MostlyCloudyWithThunderShowersIcon;

		case 'rain':
			return RainIcon;

		case 'snow':
			return SnowIcon;

		case 'mostly_cloudy_with_snow':
			return MostlyCloudyWithSnowIcon;

		case 'sleet':
			return SleetIcon;

		case 'freezing_rain':
			return FreezingRainIcon;

		case 'windy':
			return WindyIcon;

		case 'ice':
			return IceIcon;

		default:
			return undefined;
	}
}
