import React from 'react';
import { INewsAlertEntry } from '../../../contentful/types/INewsAlertEntry';
import { CrisisNewsAlert, NewsAlertBanner } from '../news-alert';
import { useCommonData } from '../../../common-data/useCommonData';

const PageAlerts = () => {
	const { newsAlerts } = useCommonData();
	return (
		<>
			{newsAlerts?.map((newsAlert: INewsAlertEntry) =>
				newsAlert.fields.type === 'crisis' ? (
					<CrisisNewsAlert
						title={newsAlert.fields.title}
						key={newsAlert.id}
						id={newsAlert.id}
						dismissLabel="Dismiss"
						body={newsAlert.fields.body}
					/>
				) : (
					<NewsAlertBanner
						title={newsAlert.fields.title}
						closeLabel="Dismiss"
						id={newsAlert.id}
						body={newsAlert.fields.body}
						key={newsAlert.id}
						link={newsAlert.fields.link}
					/>
				),
			)}
		</>
	);
};

export default PageAlerts;
