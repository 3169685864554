import React from 'react';
import styled from '@emotion/styled';
import { Responsive } from '@m2/blueprint-ui';
import { moment } from '../../../i18n/moment';
import CalendarIcon from '../../../assets/icons/calendar-icon.svg?reference';
import { getWeatherIcon } from './getWeatherIcon';
import { ILocalWeather } from './ILocalWeather';

export const LocalWeather = ({
	icon,
	temperatureImperial,
	description,
}: ILocalWeather) => {
	const WeatherIcon = icon && getWeatherIcon(icon);

	return (
		<StyledLocalWeather>
			<StyledWeather>
				<span>{WeatherIcon && <img src={WeatherIcon} alt={icon} />}</span>
				<span>{`${temperatureImperial}ºF ${description}`}</span>
			</StyledWeather>
			<StyledDateTime>
				<span>
					<img src={CalendarIcon} alt="" />
				</span>
				<div>{moment.tz().format('dddd MMM D')}</div>
			</StyledDateTime>
		</StyledLocalWeather>
	);
};

const StyledLocalWeather = styled.div`
	display: flex;
	flex-direction: column;

	max-width: 1200px;
	position: absolute;
	bottom: 80px;

	margin-left: auto;
	margin-right: auto;

	@media (${Responsive.getMediaQueryForBreakpoint('mobileXL')}) {
		display: none;
	}
`;

const StyledWeather = styled.div`
	display: flex;
	color: white;
	margin-bottom: 14px;

	span:first-of-type {
		margin-right: 8px;
		width: 24px;
	}

	img:first-of-type {
		transform: translateY(1px);
		width: 24px;
		height: auto;
	}
`;

const StyledDateTime = styled.div`
	display: flex;
	color: white;
	span:first-of-type {
		margin-right: 8px;
		width: 24px;
		margin-bottom: 0px;
	}

	div {
		color: white;
		font-weight: normal;
		margin-right: 3px;
		margin-bottom: 0px;
	}
`;
