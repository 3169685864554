import { useCallback, useState } from 'react';
import { useScrollFunction } from '../../components/common/header/hooks/useScrollFunction';

export function useScroll() {
	const [isScrolledDown, setIsScrolledDown] = useState(false);

	const scrollFunction = useCallback(() => {
		if (window.scrollY > 1 && !isScrolledDown) {
			setIsScrolledDown(true);
		} else if (window.scrollY < 1 && isScrolledDown) {
			setIsScrolledDown(false);
		}
	}, [isScrolledDown, setIsScrolledDown]);

	useScrollFunction(scrollFunction);

	return isScrolledDown;
}
